/* main.scss */
user-console th.sortable {
  cursor: pointer; }

.toolbar-container-selected:active {
  border-color: #E70000 !important; }

.saving-text {
  color: white;
  padding-left: 8em;
  padding-top: 0.5em; }

.card-wrapper {
  display: inline-block;
  margin-bottom: 5px;
  background-color: #00A4DB;
  color: #FFF;
  border-radius: 3px; }
  .card-wrapper span {
    padding: 5px;
    line-height: 2rem; }
    .card-wrapper span + span {
      border-left: 1px solid #FFF; }

.nav-back {
  float: left;
  margin-top: 15px;
  margin-right: 10px; }

main#document-viewer {
  margin-top: 15px;
  float: left;
  transition: 0.5s;
  width: calc(99% - 35px - 35px); }
  main#document-viewer.side-nav-open {
    width: 53%; }

aside#side-nav {
  margin-top: 15px;
  background: #FFF;
  overflow: hidden;
  float: right;
  margin-left: 10px;
  padding-left: 0 !important;
  transition: 0.5s;
  width: 25px; }
  aside#side-nav img {
    height: 20px;
    width: 20px;
    margin: 5px;
    border: 1px solid #DDD;
    padding: 2px;
    border-radius: 2px; }
  aside#side-nav.side-nav-open {
    overflow: auto;
    width: 40%; }
  aside#side-nav .side-nav-wrapper {
    padding-left: 25px; }

.panel-footer {
  padding: 0;
  margin: 0;
  background-color: #FFF;
  border: 1px solid #DDD;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.5rem; }

.panel-header {
  padding: 0;
  margin: 0;
  background-color: #FFF;
  border: 1px solid #DDD;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.5rem; }
  .panel-header h2 {
    font-size: 1.5rem;
    font-weight: 500;
    display: inline-block;
    padding: 0;
    margin: 0; }
  .panel-header .expand-button {
    margin: 5px;
    float: right;
    right: 30px;
    height: 40px;
    width: 40px; }

.panel-top-padding {
  margin-top: 2%; }

nav svg {
  display: inline-block;
  vertical-align: middle; }

a div span {
  display: inline-block;
  vertical-align: middle; }

ul.nav {
  margin-top: 14px; }

.svg-icon {
  display: inline-block;
  height: 30px;
  padding-left: 30px;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItdXNlciI+PHBhdGggZD0iTTIwIDIxdi0yYTQgNCAwIDAgMC00LTRIOGE0IDQgMCAwIDAtNCA0djIiPjwvcGF0aD48Y2lyY2xlIGN4PSIxMiIgY3k9IjciIHI9IjQiPjwvY2lyY2xlPjwvc3ZnPg==") no-repeat; }

.panel-fixed-width {
  width: 800px;
  margin-left: auto;
  margin-right: auto; }

@font-face {
  font-family: 'Lobster';
  src: url(../fonts/Lobster-Regular.ttf); }

.icon {
  position: relative;
  padding-left: 10px; }
  .icon.icon-home:before {
    content: "\f015";
    font-family: FontAwesome;
    left: -10px;
    font-size: 1.5em;
    position: absolute; }
  .icon.icon-user:before {
    content: "\e008";
    font-family: 'GlyphIcons Halflings';
    left: -10px;
    font-size: 1.1em;
    position: absolute; }

.table-fixed {
  table-layout: fixed;
  word-wrap: break-word; }

#selected-instance-new-comments ul {
  list-style-type: none; }

.calendar {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  overflow: hidden; }
  .calendar .calendar-nav {
    float: left;
    width: 100%;
    height: 40px;
    background-color: white; }
    .calendar .calendar-nav > * {
      height: 40px;
      line-height: 40px !important;
      display: inline-block;
      vertical-align: middle; }
    .calendar .calendar-nav > i {
      float: left;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      width: 40px;
      font-size: 1.25em;
      font-weight: bold;
      padding: 0 10px;
      cursor: pointer;
      position: relative; }
      .calendar .calendar-nav > i.fa-angle-left {
        text-align: left; }
      .calendar .calendar-nav > i.fa-angle-right {
        text-align: right;
        margin-left: -40px; }
    .calendar .calendar-nav > span {
      float: left;
      width: 100%;
      font-weight: bold;
      text-transform: uppercase;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      margin-left: -40px;
      padding-left: 50px;
      padding-right: 40px;
      text-align: center; }
  .calendar .week {
    float: left;
    width: 100%;
    border-top: solid 1px #CCCCCC;
    background-color: #FFFFFF; }
    .calendar .week > .day {
      float: left;
      width: 14.28571%;
      border-left: solid 1px #CCCCCC;
      font-size: 0.75em;
      text-align: center;
      cursor: pointer;
      height: 70px;
      line-height: 70px !important;
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
      -moz-box-sizing: border-box; }
      .calendar .week > .day:first-child {
        border-left: none; }
      .calendar .week > .day.active {
        background-color: #FEE4CC; }
    .calendar .week.names > span {
      background-color: #F5F5F7;
      height: 40px;
      line-height: 40px !important;
      display: inline-block;
      vertical-align: middle;
      font-size: 13px;
      text-align: left;
      padding-left: 5px;
      font-weight: bold; }

.task-count {
  display: inline-block;
  position: absolute;
  top: 7px;
  right: -5px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  background: red;
  color: white;
  border-radius: 50%; }

#user-info {
  border: 1px solid #999;
  display: block; }
  #user-info li {
    list-style: none;
    padding: 0 5px;
    display: inline; }
  #user-info ul {
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0; }

.summary-card-container {
  display: inline-block;
  position: relative; }

.summary-card {
  background-color: #FFFFFF;
  border: 2px solid #999;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 2px #cccccc; }

#user-navigation {
  border: 1px solid #999;
  display: block;
  padding: 5px 15px;
  background: #e5e5e5;
  width: 50%;
  border-radius: 5px; }

.fileupload-list {
  list-style-type: none;
  margin: 15px; }
  .fileupload-list .fileupload-listitem {
    padding: 5px 15px;
    border: 1px solid #999;
    border-radius: 4px;
    float: left; }
    .fileupload-list .fileupload-listitem span, .fileupload-list .fileupload-listitem a {
      margin: 0 5px; }

.body-section {
  margin-top: -20px; }

.no-margin-bottom {
  margin-bottom: 0; }

.no-box-shadow {
  border: none;
  box-shadow: none; }

.property-label {
  padding-top: 7px;
  text-transform: uppercase;
  text-align: left; }

.property-select {
  display: inline-block; }

.nav-property {
  background-color: #FFFFFF;
  padding: 1rem 2rem;
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC; }
  .nav-property .form-group {
    margin-bottom: 0; }

.tyall-panel {
  background-color: #FFFFFF;
  padding: 1rem 2rem;
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC; }
  .tyall-panel.calendar-panel {
    background-color: #F5F5F7; }

.group-inbox {
  background-color: #FFFFFF;
  padding: 1rem 2rem;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC; }

header {
  background: #e5e5e5;
  font-weight: 500;
  font-size: 24px;
  text-align: center; }

/* Copyright 2014 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.toolbar-container {
  background-color: #2B3C4D;
  padding: 10px;
  color: black; }
  .toolbar-container .toolbar-default {
    display: inline-block; }
  .toolbar-container .toolbar-edit {
    display: inline-block; }
  .toolbar-container .toolbar-btn {
    background-color: #FFF;
    margin: 0;
    padding: 5px 10px;
    border-radius: 6px;
    height: 35px; }
    .toolbar-container .toolbar-btn:hover, .toolbar-container .toolbar-btn:active {
      background-color: #DBDBDB; }
    .toolbar-container .toolbar-btn i {
      font-weight: 100; }

.toolbar {
  height: 50px;
  background: #323639;
  position: relative; }
  .toolbar .btn {
    position: relative;
    top: 50%;
    transform: translateY(-50%); }

.annotationLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; }

section#workflow-instance {
  border: solid 1px black; }

section#document-viewer {
  border: solid 1px black; }

.textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1.0; }

.textLayer > div {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%; }

.textLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: #b400aa;
  border-radius: 4px; }

.textLayer .highlight.begin {
  border-radius: 4px 0px 0px 4px; }

.textLayer .highlight.end {
  border-radius: 0px 4px 4px 0px; }

.textLayer .highlight.middle {
  border-radius: 0px; }

.textLayer .highlight.selected {
  background-color: darkgreen; }

.textLayer ::selection {
  background: blue; }

.textLayer ::-moz-selection {
  background: blue; }

.textLayer .endOfContent {
  display: block;
  position: absolute;
  left: 0px;
  top: 100%;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none; }

.textLayer .endOfContent.active {
  top: 0px; }

.pdfViewer .canvasWrapper {
  box-shadow: 0 0 3px #bbb;
  position: relative; }

.pdfViewer .page {
  margin-bottom: 10px;
  margin: 60px auto 5px auto;
  position: relative; }

#content-wrapper {
  overflow: auto;
  height: 600px;
  background: #32465A;
  position: relative; }

.st-sort-ascent:before {
  content: '\25B2'; }

.st-sort-descent:before {
  content: '\25BC'; }

.btn-iteration__wrapper {
  margin: 5px auto; }
  .btn-iteration__wrapper .btn-iteration {
    width: 100px; }

.input-space {
  padding-top: 1em;
  padding-bottom: 1em; }

.remove-input-group-padding {
  padding-left: initial !important;
  padding-right: initial !important; }

.row-top-space {
  padding-top: 2em; }

.table-scroll-div {
  overflow-y: auto;
  height: 300px;
  margin-top: 2em;
  margin-bottom: 2em; }

.table-scroll-div-large {
  overflow-y: auto;
  height: 600px;
  margin-top: 2em;
  margin-bottom: 2em; }

.table-standard input {
  border-radius: 3px;
  border: none; }

.table-standard table thead {
  background-color: #634fb4;
  color: white; }

.table-standard table th {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px 15px; }

.table-standard table td {
  padding: 6px 8px; }

.center-text {
  text-align: center; }

.navbar-brand {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 50px; }
  .navbar-brand img {
    height: 50px;
    float: left;
    display: inline-block; }
  .navbar-brand h1 {
    color: #fff;
    vertical-align: baseline;
    padding-left: 1rem;
    padding-top: 1rem;
    font-weight: 700;
    display: inline-block;
    margin: 0; }

.aduit-checkbox {
  padding-top: 2em; }

.audit-form {
  width: 100%;
  box-sizing: border-box; }
  .audit-form .input-field {
    margin-top: 20px; }
    .audit-form .input-field.input-field--narrow {
      width: 50%; }
    .audit-form .input-field .clearfix::after {
      content: "";
      clear: both;
      display: table; }
    .audit-form .input-field input {
      border: 1px solid #CCC;
      border-radius: 4px;
      display: block;
      line-height: 1.8em;
      width: 100%; }
      .audit-form .input-field input:focus {
        outline: 1px solid #80BDFF; }
    .audit-form .input-field label {
      display: block;
      font-weight: normal; }
    .audit-form .input-field .input-field--required {
      font-weight: bold;
      padding-left: 3px;
      color: red; }
    .audit-form .input-field .error-messages {
      float: right;
      display: block; }
    .audit-form .input-field .error-messages__item {
      display: block;
      color: red;
      text-align: right; }
    .audit-form .input-field.input-field--error {
      border: 2px solid red;
      padding: 5px 5px;
      border-radius: 4px; }
  .audit-form .button-container {
    float: right;
    margin-top: 10px; }

.mentions-pagination-wrapper {
  display: flex;
  justify-content: center; }

.summary-card {
  background-color: #FFFFFF;
  border: 2px solid #999;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 2px #cccccc; }

.summary-card-container {
  display: inline-block;
  position: relative; }

.daily-task-count {
  display: inline-block;
  position: absolute;
  top: 7px;
  right: -8px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  background: red;
  color: white;
  border-radius: 50%; }

.non-daily-task-count {
  display: inline-block;
  position: absolute;
  bottom: 14px;
  right: -8px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  background: #00A4DB;
  color: white;
  border-radius: 50%; }

.calendar {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  overflow: hidden; }
  .calendar .calendar-nav {
    float: left;
    width: 100%;
    height: 40px;
    background-color: white; }
    .calendar .calendar-nav > * {
      height: 40px;
      line-height: 40px !important;
      display: inline-block;
      vertical-align: middle; }
    .calendar .calendar-nav > i {
      float: left;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      width: 40px;
      font-size: 1.25em;
      font-weight: bold;
      padding: 0 10px;
      cursor: pointer;
      position: relative; }
      .calendar .calendar-nav > i.fa-angle-left {
        text-align: left; }
      .calendar .calendar-nav > i.fa-angle-right {
        text-align: right;
        margin-left: -40px; }
    .calendar .calendar-nav > span {
      float: left;
      width: 100%;
      font-weight: bold;
      text-transform: uppercase;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      margin-left: -40px;
      padding-left: 50px;
      padding-right: 40px;
      text-align: center; }
  .calendar .week {
    float: left;
    width: 100%;
    border-top: solid 1px #CCCCCC;
    background-color: #FFFFFF; }
    .calendar .week > .day {
      float: left;
      width: 14.28571%;
      border-left: solid 1px #CCCCCC;
      font-size: 0.75em;
      text-align: center;
      cursor: pointer;
      height: 70px;
      line-height: 70px !important;
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
      -moz-box-sizing: border-box; }
      .calendar .week > .day:first-child {
        border-left: none; }
      .calendar .week > .day.active {
        background-color: #FEE4CC; }
    .calendar .week.names > span {
      background-color: #F5F5F7;
      height: 40px;
      line-height: 40px !important;
      display: inline-block;
      vertical-align: middle;
      font-size: 13px;
      text-align: left;
      padding-left: 5px;
      font-weight: bold; }

inbox .task {
  font-weight: bold;
  letter-spacing: 0.05em;
  border-radius: 3px;
  padding: 2px 10px;
  color: #fff; }
  inbox .task.task--daily {
    background-color: red; }
    inbox .task.task--daily:before {
      content: "D"; }
  inbox .task.task--non-daily {
    background-color: #00A4DB; }
    inbox .task.task--non-daily:before {
      content: "ND"; }

inbox .unclaimed-summary {
  padding: 10px 0;
  position: relative;
  font-size: 20px; }
  inbox .unclaimed-summary .unclaimed-summary-title {
    padding-left: 4rem; }
  inbox .unclaimed-summary .unclaimed-summary-count {
    display: inline-block;
    left: 0;
    margin: 0;
    position: absolute;
    background-color: #F97800;
    color: #FFF;
    border-radius: 5px;
    padding: 0 5px; }

inbox .inbox-header .inbox-date {
  font-size: 2rem;
  display: block;
  border-bottom: 1px solid;
  padding: 1rem 0; }

inbox .inbox-detail {
  padding-top: 3rem;
  overflow-x: auto; }
  inbox .inbox-detail thead {
    border-bottom: 1px solid; }
  inbox .inbox-detail th {
    text-transform: uppercase; }
  inbox .inbox-detail th, inbox .inbox-detail td {
    padding-top: 1rem;
    padding-right: 9px; }

.tyall-panel {
  background-color: #FFFFFF;
  padding: 1rem 2rem;
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC; }
  .tyall-panel.calendar-panel {
    background-color: #F5F5F7; }

.group-inbox {
  background-color: #FFFFFF;
  padding: 1rem 2rem;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC; }

ul.mention-list {
  list-style-type: none;
  padding: 0; }
  ul.mention-list li {
    background-color: #304758;
    margin: 2px 0;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 4px;
    text-align: center; }

.selected-row td {
  background-color: #00A4DB;
  color: #FFF; }
  .selected-row td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; }
  .selected-row td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; }

.tabdata__counter {
  color: white;
  background-color: #2C3F4D;
  border-radius: 50%;
  padding: 2px 5px;
  margin-left: 2px; }

.due-date {
  display: inline-block;
  color: black;
  margin: auto; }

.button-padding {
  padding-left: 1em;
  padding-right: 1em; }

.task-list__nav {
  margin-top: 10px;
  display: flex;
  align-items: center; }
  .task-list__nav .task-list__nav-item {
    margin-left: 5px; }
  .task-list__nav button {
    width: 100px; }

.icon-padding {
  padding-top: 0.5em; }

.task-list__date {
  display: flex;
  justify-content: center; }
  .task-list__date time {
    font-size: 30px;
    margin: 5px 0; }

.task-list ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  width: 90%;
  margin: auto; }

.task-list ul li {
  list-style: none;
  flex: 0 0 43%; }

.task-list {
  width: 90%;
  margin: auto;
  padding: 0; }
  .task-list .task-card {
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    background-color: #00A4DB;
    border-radius: 4px;
    color: #FFF;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    margin: 1em; }
    .task-list .task-card .task-card__history {
      font-size: 12px; }

non-daily-launcher input {
  border-radius: 3px;
  border: none; }

non-daily-launcher table thead {
  background-color: #634fb4;
  color: white; }

non-daily-launcher table th {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px 15px; }

non-daily-launcher table td {
  padding: 6px 8px; }

.table-scroll-launcher {
  overflow-y: auto;
  height: 650px;
  margin-top: 2em;
  margin-bottom: 2em; }

.create-user-form {
  width: 100%;
  box-sizing: border-box; }
  .create-user-form .input-field {
    margin-top: 20px;
    width: 100%; }
    .create-user-form .input-field.input-field--narrow {
      width: 50%; }
    .create-user-form .input-field .clearfix::after {
      content: "";
      clear: both;
      display: table; }
    .create-user-form .input-field input {
      border: 1px solid #CCC;
      border-radius: 4px;
      display: block;
      line-height: 1.8em;
      width: 100%; }
      .create-user-form .input-field input:focus {
        outline: 1px solid #80BDFF; }
    .create-user-form .input-field label {
      display: block;
      font-weight: normal; }
    .create-user-form .input-field .input-field--required {
      font-weight: bold;
      padding-left: 3px;
      color: red; }
    .create-user-form .input-field .error-messages {
      float: right;
      display: block; }
    .create-user-form .input-field .error-messages__item {
      display: block;
      color: red;
      text-align: right; }
    .create-user-form .input-field.input-field--error {
      border: 2px solid red;
      padding: 5px 5px;
      border-radius: 4px; }
  .create-user-form .button-container {
    float: right;
    margin-top: 10px; }

.create-schedule-form {
  width: 100%;
  box-sizing: border-box; }
  .create-schedule-form .input-field {
    margin-top: 20px;
    width: 100%; }
    .create-schedule-form .input-field.input-field--narrow {
      width: 50%; }
    .create-schedule-form .input-field .clearfix::after {
      content: "";
      clear: both;
      display: table; }
    .create-schedule-form .input-field input {
      border: 1px solid #CCC;
      border-radius: 4px;
      display: block;
      line-height: 1.8em;
      width: 100%; }
      .create-schedule-form .input-field input:focus {
        outline: 1px solid #80BDFF; }
    .create-schedule-form .input-field label {
      display: block;
      font-weight: normal; }
    .create-schedule-form .input-field .input-field--required {
      font-weight: bold;
      padding-left: 3px;
      color: red; }
    .create-schedule-form .input-field .error-messages {
      float: right;
      display: block; }
    .create-schedule-form .input-field .error-messages__item {
      display: block;
      color: red;
      text-align: right; }
    .create-schedule-form .input-field.input-field--error {
      border: 2px solid red;
      padding: 5px 5px;
      border-radius: 4px; }
  .create-schedule-form .button-container {
    float: right;
    margin-top: 10px; }

.create-wfd-form {
  width: 100%;
  box-sizing: border-box; }
  .create-wfd-form .input-field {
    margin-top: 20px;
    width: 100%; }
    .create-wfd-form .input-field.input-field--narrow {
      width: 50%; }
    .create-wfd-form .input-field .clearfix::after {
      content: "";
      clear: both;
      display: table; }
    .create-wfd-form .input-field input {
      border: 1px solid #CCC;
      border-radius: 4px;
      display: block;
      line-height: 1.8em;
      width: 100%; }
      .create-wfd-form .input-field input:focus {
        outline: 1px solid #80BDFF; }
    .create-wfd-form .input-field label {
      display: block;
      font-weight: normal; }
    .create-wfd-form .input-field .input-field--required {
      font-weight: bold;
      padding-left: 3px;
      color: red; }
    .create-wfd-form .input-field .error-messages {
      float: right;
      display: block; }
    .create-wfd-form .input-field .error-messages__item {
      display: block;
      color: red;
      text-align: right; }
    .create-wfd-form .input-field.input-field--error {
      border: 2px solid red;
      padding: 5px 5px;
      border-radius: 4px; }
  .create-wfd-form .button-container {
    float: right;
    margin-top: 10px; }

.auditor-console table thead {
  background-color: #634fb4;
  color: white;
  position: sticky !important;
  top: 0 !important; }

.auditor-console table th {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: sticky !important;
  top: 0 !important;
  padding: 10px 15px; }

.auditor-console table td {
  border: 1px solid #eee;
  padding: 6px 8px; }

.user-console .users {
  table-layout: fixed;
  white-space: nowrap;
  font-size: 14px;
  border-collapse: separate;
  border-spacing: 0 5px; }
  .user-console .users thead {
    background-color: #634fb4;
    color: white; }
    .user-console .users thead th {
      padding-left: 10px; }
  .user-console .users tbody {
    color: #000000; }
    .user-console .users tbody tr {
      background-color: #DCDCDC; }
      .user-console .users tbody tr td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  .user-console .users .header-row__selector {
    padding-left: 2em;
    padding-right: 2em;
    height: 45px;
    width: 45px;
    text-align: center;
    vertical-align: middle; }
  .user-console .users .header-row__username {
    width: 410px; }
  .user-console .users .header-row__enabled {
    width: 45px; }
  .user-console .users .header-row__usergroups {
    width: 510px; }
  .user-console .users .data-row__usergroups {
    vertical-align: top; }
  .user-console .users .header-row__propertyprofiles {
    width: 510px; }
  .user-console .users .data-row__propertyprofiles {
    vertical-align: top; }

user-group-card-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; }
  user-group-card-list button {
    margin-top: 10px; }

.user-group-card {
  display: flex;
  flex-direction: row;
  height: 25px;
  margin-top: 5px; }
  .user-group-card .user-group-card__name {
    background-color: #C1B8E0;
    padding-left: 10px;
    padding-right: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
    .user-group-card .user-group-card__name > span {
      display: inline-block;
      vertical-align: middle; }
  .user-group-card .user-group-card__btn {
    cursor: pointer;
    background-image: url("../../content/images/cross.svg");
    flex: 0 0 25px; }
    .user-group-card .user-group-card__btn:hover {
      background-image: url("../../content/images/cross-hover.svg"); }

property-profile-card-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; }
  property-profile-card-list button {
    margin-top: 10px; }

.property-profile-card {
  display: flex;
  flex-direction: row;
  height: 25px;
  margin-top: 5px; }
  .property-profile-card .property-profile-card__name {
    background-color: #C1B8E0;
    padding-left: 10px;
    padding-right: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
    .property-profile-card .property-profile-card__name > span {
      display: inline-block;
      vertical-align: middle; }
  .property-profile-card .property-profile-card__btn {
    cursor: pointer;
    background-image: url("../../content/images/cross.svg");
    flex: 0 0 25px; }
    .property-profile-card .property-profile-card__btn:hover {
      background-image: url("../../content/images/cross-hover.svg"); }

.create-audit-basket {
  padding-top: 2em; }
  .create-audit-basket .selector__header {
    display: flex;
    height: 40px;
    align-items: center; }
    .create-audit-basket .selector__header h2 {
      margin: 0 20px 0 0;
      font-size: 20px;
      letter-spacing: 0.5px; }
    .create-audit-basket .selector__header button {
      border-radius: 50%;
      height: 30px;
      width: 30px;
      margin: 0;
      padding: 0;
      font-size: 20px; }
  .create-audit-basket .selector__content p {
    font-style: italic; }
  .create-audit-basket .create-audit-basket__buttons {
    margin-top: 40px; }
  .create-audit-basket table {
    width: 90%;
    table-layout: fixed;
    margin-bottom: 20px; }
    .create-audit-basket table td, .create-audit-basket table th {
      border: 1px solid #eee;
      padding: 4px 15px; }
  .create-audit-basket form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%; }
    .create-audit-basket form input {
      background-color: #eee;
      border: none;
      padding: 12px 15px;
      margin: 8px; }

.audit-basket-container table thead {
  background-color: #634fb4;
  color: white; }

.audit-basket-container table th {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px 15px; }

.audit-basket-container table td {
  padding: 6px 17px; }

.read-audit-basket table thead {
  background-color: #634fb4;
  color: white; }

.read-audit-basket table th {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px 15px; }

.read-audit-basket table td {
  padding: 6px 17px; }

input[type=checkbox].custom-checkbox {
  display: none; }
  input[type=checkbox].custom-checkbox + label {
    margin: 0; }

input[type=checkbox]:checked.custom-checkbox + label:before {
  display: inline-block;
  content: "";
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='white' width='16' height='16' viewBox='0 0 4.233 4.233'><path d='M4 .233A.764.764 0 0 0 3.44 0H.794a.765.765 0 0 0-.561.233.765.765 0 0 0-.233.56V3.44c0 .218.078.405.233.56a.765.765 0 0 0 .56.233H3.44A.764.764 0 0 0 4 4a.765.765 0 0 0 .233-.56V.794A.765.765 0 0 0 4 .233zm-.42 1.302L1.888 3.227a.17.17 0 0 1-.124.053.17.17 0 0 1-.124-.053l-.987-.986a.17.17 0 0 1-.052-.124.17.17 0 0 1 .052-.124l.281-.281a.17.17 0 0 1 .124-.053.17.17 0 0 1 .124.053l.582.581L3.05 1.006a.17.17 0 0 1 .124-.052.17.17 0 0 1 .124.052l.281.281a.17.17 0 0 1 .053.124.17.17 0 0 1-.053.124z' /></svg>"); }

input[type=checkbox].custom-checkbox + label:before {
  display: inline-block;
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='white' width='16' height='16' viewBox='0 0 4.233 4.233'><path d='M.794 0a.765.765 0 0 0-.561.233.764.764 0 0 0-.233.56V3.44c0 .218.077.406.233.56a.765.765 0 0 0 .56.233H3.44A.764.764 0 0 0 4 4.001a.765.765 0 0 0 .233-.561V.794A.764.764 0 0 0 4 .234.764.764 0 0 0 3.44 0zm.248.397h2.15c.177 0 .33.063.455.189.126.126.19.278.19.456v2.15c0 .177-.064.33-.19.455a.621.621 0 0 1-.455.19h-2.15a.621.621 0 0 1-.456-.19.621.621 0 0 1-.19-.455v-2.15c0-.178.064-.33.19-.456a.621.621 0 0 1 .456-.19z'/></svg>"); }

.modal-dialog {
  width: 750px !important; }

.left-footer {
  text-align: initial; }

.right-footer {
  text-align: right; }

.add-workflow-instance-dialog table thead {
  background-color: #634fb4;
  color: white;
  position: sticky !important;
  top: 0 !important; }

.add-workflow-instance-dialog table th {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: sticky !important;
  top: 0 !important;
  padding: 10px 15px; }

.add-workflow-instance-dialog table td {
  border: 1px solid #eee;
  padding: 6px 8px; }

.tableScroll {
  overflow-y: auto;
  height: 300px;
  margin-top: 2em;
  margin-bottom: 2em; }

.tableFixedHeading {
  position: sticky !important;
  top: 0 !important; }

.add-auditor-dialog table thead {
  background-color: #634fb4;
  color: white;
  position: sticky !important;
  top: 0 !important; }

.add-auditor-dialog table th {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: sticky !important;
  top: 0 !important;
  padding: 10px 15px; }

.add-auditor-dialog table td {
  border: 1px solid #eee;
  padding: 6px 8px; }

.tableScroll {
  overflow-y: auto;
  height: 300px;
  margin-top: 2em;
  margin-bottom: 2em; }

.tableFixedHeading {
  position: sticky !important;
  top: 0 !important; }
